import { MultiStepForm } from '@/components/MultiStepForm'; // Adjust the import path as necessary

function Home() {
  return (
    <div
      className="relative min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url('/pool.gif')` }}
    >
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-30"></div>

      {/* Main Container */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen w-full px-4">
        {/* Form Container */}
        <div className="w-full max-w-sm bg-white/50 backdrop-blur-lg px-6 py-6 rounded-lg shadow-lg">
          <MultiStepForm />
        </div>
      </div>
    </div>
  );
}

export default Home;
