import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export default function StepOne() {
  const { control, setValue, watch } = useFormContext();
  const rsvp = watch("rsvp");

  const handleRsvpClick = (value: "yes" | "no") => {
    setValue("rsvp", value);
  };

  useEffect(() => {
    // Remove focus from any initially focused element
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, []);


  return (

    
    <div className=" px-8 rounded-lg max-w-md mx-auto text-center">
      {/* Title */}
      <div className="flex justify-center mb-4">
    <img 
      src="/Welcome.png" 
      alt="Welcome" 
      className="w-full max-w-md object-contain"
    />
  </div>
      <p className="text-lg text-gray-600 mb-8">
        You have been invited to <br />
        <span className="font-bold text-blue-600">Branden & Samantha's</span>
        <br /> 
        <span className="font-bold">2025</span> 
       <br /> 4th of July <br />
        Wedding Pool Party!
      </p>

      {/* First Name Field */}
      <div className="text-left">
        <FormField
          control={control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-gray-700">First Name</FormLabel>
              <FormControl>
                <Input {...field} className="w-full mt-1 border-gray-600 rounded-lg p-2 focus:border-blue-500" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Last Name Field */}
        <FormField
          control={control}
          name="lastName"
          render={({ field }) => (
            <FormItem className="mt-4">
              <FormLabel className="text-gray-700">Last Name</FormLabel>
              <FormControl>
                <Input {...field} className="w-full mt-1 border-gray-600 rounded-lg p-2 focus:border-blue-500" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      {/* RSVP Label */}
      <div autoFocus={false} className="text-center mt-6 font-semibold text-gray-800">RSVP</div>

      {/* Yes/No Buttons with Conditional Styling */}
      <div className="flex justify-center gap-4 mt-2 text-sm">
        
        <button 
          autoFocus={false}
          type="button"
          onClick={() => handleRsvpClick("yes")}
          className={`px-4 py-2 rounded-lg ${
            rsvp === "yes" ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-800"
          } transition-colors duration-200`}
        >
          YES
        </button>
        <button
          autoFocus={false}
          type="button"
          onClick={() => handleRsvpClick("no")}
          className={`px-4 py-2 rounded-lg ${
            rsvp === "no" ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-800"
          } transition-colors duration-200`}
        >
          NO
        </button>
      </div>
    </div>
  );
}
